import React from "react";
import "./stu.css";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper/modules";

export const Cards = ({ text, author, role }) => {
  return (
    <>
      <div className="card">
        <div className="card-inner">
          <blockquote className="testimonial-text">{text}</blockquote>
          <div className="testimonial-author">
            <div className="author-details">
              <p className="author-name">{author}</p>
              <p className="author-role">{role}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



const Student_Review = () => {
  // Define an array of student information
  const studentData = [
    {
      text: "I wholeheartedly recommend this i-science class to any student looking to strengthen their foundation in science subjects. The dedicated instructors, Interactive teaching methods, practical learning approach, and supportive learning environment make it an ideal platform for students aiming to achieve academic excellence in science. Trust me, you won't be disappointed",
      author: "Sheetal Lahmate",
      role: "MBBS (Mumbai)",
    },
    {
      text: "I am delighted to share that my performance has significantly improved since joining I-Science. Not only have my grades gone up, but my overall confidence and enthusiasm for solence have also soared. The coaching class has equipped me with the necessary knowledge, skills, and strategies to tackle complex scientific concepts and excel in exams.",
      author: "Rajiv Nair",
      role: "(B.Tech ICT)",
    },
    {
      text: "I-Science offered ample resources and study materials, Including textbooks, reference books, and online resources, to supplement our learning. These resources were comprehensive and well-organized, making it easier for me to revise and reinforce my understanding of different topics.",
      author: "Dr. Disha Wagela",
      role: "BDS (Mumbai)",
    },
    {
      text: 
      "I can confidently say that enrolling in the science coaching class was one of the best decisions I made for my academic joumey. As a student struggling with science subjects, I was in dire need of guidance and support to improve my understanding and grades. Thankfully, this coaching class exceeded my expectations in every way.",
      author: "Himanshu Vyas",
      role: "BAMS",
    },
    {
      text: "I can confidently say that enrolling in the I-science coaching class was one of the best decisions I made for my academic journey. As a student struggling with science subjects, I was in dire need of guidance and support to improve my understanding and grades. Thankfully, this coaching class exceeded my expectations in every way",
      author: "Dr. Vishakha Jain",
      role: "BDS (Mumbai)",
    },
    {
      text: "The highly knowledgeable and dedicated Instructors in the I-science class not only possessed a deep understanding of the subject matter but also had a passion for teaching. Their teaching methods were engaging, Interactive, and tailored to suit the needs of Individual students. They were always available to answer our questions, clarify concepts, and provide additional resources whenever needed.",
      author: "Ruchita Laswante",
      role: "ICT"
    },
  ];

  return (
    <div>
      <div className="container">
        <h1 className="heading">Testimonials</h1>
        <Swiper
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            modifier: 1.5,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          autoplay={{ delay: 2000}}
          className="swiper_container_2"
        >
          {studentData.map((student, index) => (
            <SwiperSlide key={index}>
              <Cards text={student.text} author={student.author} role={student.role} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Student_Review;
