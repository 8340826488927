import React from "react";
import "./choose.css";
import d from "../../Assets/doubt.jpg"
import sci from "../../Assets/whyus/science.png"
import r from "../../Assets/whyus/record.png"
import t from "../../Assets/whyus/time.png"
import l from "../../Assets/whyus/learn.png"
import b from "../../Assets/whyus/books.png"
import x from "../../Assets/whyus/coach.png"
import k from "../../Assets/whyus/commu.png"
const ScienceClasses = (props) => {
  ;

  return (
    <>
      <div className="choosecontainer">
        <h1>{props.title}</h1>
        <div className="chooserow_bene">
          <div className="chooseservice" onClick={() => navigator.vibrate([100, 300, 100, 300])}>
            {/* <i className="fas fa-chalkboard-teacher"></i> */}
            <img src={d} className="whyimg" alt="teacher"/>
            <h2>Experienced Science Educators</h2>
            <p>
              Our team consists of experienced science educators specializing in teaching 11th and 12th-grade students. We understand the specific needs of students preparing for JEE and NEET exams.
            </p>
          </div>
          <div className="chooseservice">
            <img src={sci} className="whyimg" alt="science"/>
            <h2>Focus on Science Concepts</h2>
            <p>
              Our classes prioritize a deep understanding of science concepts required for JEE and NEET. We ensure that students grasp the fundamentals, laying a strong foundation for their future studies.
            </p>
          </div>
          <div className="chooseservice">
            <img src={r} className="whyimg" alt="records"/>
            <h2>Our Proven Past Track Record</h2>
            <p>
              With a proven track record of success, our students consistently excel in their 11th, 12th-grade exams, JEE,Mhtcet and NEET. Join us to be a part of this success story.
            </p>
          </div>
          <div className="chooseservice">
          <img src={t} className="whyimg" alt="time management"/>
            <h2>Effective Time Management</h2>
            <p>
              We understand the importance of time in competitive exams. Our classes focus on effective time management strategies, ensuring students can solve problems efficiently during exams.
            </p>
          </div>
          <div className="chooseservice">
          <img src={l} className="whyimg" alt="learning"/>
            <h2>Interactive Learning Platforms</h2>
            <p>
              Embrace technology with our interactive learning platforms. Access study materials, recorded lectures, and practice tests online, providing a flexible and convenient learning experience.
            </p>
          </div>
          <div className="chooseservice">
            
            <img src={b} className="whyimg" alt="books"/>
            <h2>Comprehensive Study Material</h2>
            <p>
              Receive comprehensive study materials designed specifically for 11th and 12th-grade science students. Our materials cover the entire syllabus of JEE and NEET, ensuring thorough preparation.
            </p>
          </div>
          <div className="chooseservice">
          <img src={x} className="whyimg" alt="books"/>
            {/* <i className="fas fa-chalkboard"></i> */}
            <h2>Specialized Personalized Coaching</h2>
            <p>
              Benefit from personalized coaching sessions where our educators address individual learning needs. Get the attention you deserve to succeed in your science studies and competitive exams.
            </p>
          </div>
          <div className="chooseservice">
            <img src={k} className="whyimg" alt="community"/>
            <h2>Supportive Learning Community</h2>
            <p>
              Join a supportive learning community where students collaborate, share knowledge, and motivate each other. Our community fosters a positive and encouraging environment for academic growth.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

ScienceClasses.defaultProps = {
  title: "Why Choose I-Science ?",
};

export default ScienceClasses;
