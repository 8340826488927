import React, { useEffect } from "react";
import "./Collage.css"; // Import your CSS file for styling
import img1 from "../../Assets/Branch/vileparle1.jpg";
import img2 from "../../Assets/Branch/vileparle2.jpg";
import img3 from "../../Assets/Branch/vileparle3.jpg";
import img4 from "../../Assets/Branch/vileparle4.jpg";
import img5 from "../../Assets/Branch/vileparle5.jpg";
import img6 from "../../Assets/Branch/vileparle6.jpg";

import Events from "./Events";
const BranchGlimpses = ({ popup }) => {
  const images = [img1, img2, img3, img4, img5, img6];

  useEffect(() => {
    window.scrollTo({
      top: 1,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className={popup ? "blurify" : ""}>
      <div className="heading">
        <p>Our Branch Glimpses</p>
      </div>
      <div className="collage-container">
        {images.map((image, index) => (
          <div key={index} className={`photo photo-${index + 1}`}>
            <img src={image} alt={`Branch Glimpse ${index + 1}`} />
          </div>
        ))}
      </div>
      {/* <Events/> */}
    </div>
  );
};

export default BranchGlimpses;
