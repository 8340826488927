import React, { useState, useEffect } from "react";
import courses from "../../Assets/courses.png";
import "./courses.css";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import Lottie from "lottie-react";
import data from "../../Assets/Lottie/online_test.json";
import test from "../../Assets/Lottie/test.json";
const AboutSection = ({ title, description, image, reversed, idd }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const getImage = async () => {
      try {
        const importedImage = await import(`../../Assets/${image}`);
        setImageUrl(importedImage.default);
      } catch (error) {
        console.error(`Error importing image: ${image}`, error);
      }
    };

    getImage();
  }, [image]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <motion.div
      initial={{ x: "-100%", opacity: 0 }}
      whileInView={{ x: 0, opacity: 1 }}
      exit={{
        x: "-100%",
        opacity: 0,
        transition: { duration: 0, delay: 0 },
      }}
      transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
    >
      <div
        className={`about-section_new ${reversed ? "reverse" : ""}`}
        id={idd}
      >
        <div className="about-section-image_new">
          {imageUrl && <img src={imageUrl} alt={title} />}
        </div>
        <div className="about-section-content_new">
          <h2 className="exam-title">{title}</h2>
          <p className="about-section-description_new">{description}</p>
        </div>
      </div>
    </motion.div>
  );
};

const Sci = ({ popup }) => {
  return (
    <div className={popup ? "blurify" : ""}>
      <div className="courses-container">
        <div className="img-cont">
          <img src={courses} alt="courses_we" className="courses-img" />
        </div>
        <div className="courses-info">
          <h2 className="exam-title">11th & 12th Science</h2>
          <p className="exam-description">
            Our coaching institute offers comprehensive courses for 11th and
            12th Science students. We provide expert guidance and a conducive
            learning environment to help students excel in their academic
            journey. Our courses cover the entire syllabus, ensuring a strong
            foundation in science subjects.
          </p>
          <h3 className="study-techniques-title">Our Study Techniques</h3>
          <ul className="study-techniques-list">
            <Link to="regular-sessions" smooth={true} duration={500}>
              <li className="loop_btn current">Regular Classroom Sessions</li>
            </Link>
            <Link to="online-tests" smooth={true} duration={500}>
              <li className="loop_btn current">Interactive Online Tests</li>
            </Link>
            <Link to="study-material" smooth={true} duration={500}>
              <li className="loop_btn current">Comprehensive Study Material</li>
            </Link>
            <Link to="doubt-sessions" smooth={true} duration={500}>
              <li className="loop_btn current">Doubt Clearing Sessions</li>
            </Link>
            <Link to="mock-tests" smooth={true} duration={500}>
              <li className="loop_btn current">
                Mock Tests and Practice Papers
              </li>
            </Link>
          </ul>
          <p className="study-techniques-description">
            Our coaching institute employs a variety of study techniques to
            ensure comprehensive preparation for 11th and 12th Science. From
            traditional classroom sessions to cutting-edge online tests, we
            provide a well-rounded learning experience.
          </p>
        </div>
      </div>

      <motion.div
        initial={{ x: "-100%", opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        exit={{
          x: "-100%",
          opacity: 0,
          transition: { duration: 0, delay: 0 },
        }}
        transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
        id="regular-sessions"
      >
        <div className="about-section_new ">
          <div className="about-section-image_new">
            <img
              src="https://ideogram.ai/api/images/direct/ZmEzrJY5R2e3iudQkBePgQ.png"
              style={{ transform: "scale(0.8)" }}
              alt="as"
            />
          </div>
          <div className="about-section-content_new">
            <h2 className="exam-title">Regular Classroom Sessions</h2>
            <p className="about-section-description_new">
              Engage in immersive traditional classroom sessions led by seasoned
              instructors. Our experienced educators foster interactive
              discussions, providing you with personalized attention to deepen
              your understanding of key concepts. Benefit from a dynamic
              learning environment designed to enhance your academic experience
              and prepare you for success.
            </p>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial={{ x: "-100%", opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        exit={{
          x: "-100%",
          opacity: 0,
          transition: { duration: 0, delay: 0 },
        }}
        transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
        id="online-tests"
      >
        <div className="about-section_new reverse">
          <div className="about-section-image_new">
            <Lottie animationData={data} key={2}></Lottie>
          </div>
          <div className="about-section-content_new">
            <h2 className="exam-title">Interactive Online Tests</h2>
            <p className="about-section-description_new">
              Immerse yourself in a cutting-edge learning experience through
              interactive online tests. Carefully designed to simulate real exam
              conditions, these tests offer instant feedback and detailed
              performance analytics. Track your progress, identify areas for
              improvement, and refine your exam-taking skills. Elevate your
              preparation with our state-of-the-art online testing platform
            </p>
          </div>
        </div>
      </motion.div>

      <AboutSection
        title="Comprehensive Study Material"
        description="Access a comprehensive repository of study materials covering the entire syllabus. Our extensive resources include textbooks, digital content, and practice problems, ensuring a thorough preparation journey. Dive into a wealth of educational materials curated to provide you with a well-rounded and in-depth understanding of each subject."
        image="study.png"
        idd="study-material"
      />

      <AboutSection
        title="Doubt Clearing Sessions"
        description="Join dedicated doubt clearing sessions designed to offer personalized assistance on challenging topics. Our expert instructors are readily available to address your queries and provide in-depth explanations. Participate in collaborative learning environments where you can clarify doubts, gain clarity, and strengthen your grasp on complex subjects."
        image="doubt.jpg"
        idd="doubt-sessions"
        reversed={true}
      />

      <motion.div
        initial={{ x: "-100%", opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        exit={{
          x: "-100%",
          opacity: 0,
          transition: { duration: 0, delay: 0 },
        }}
        transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
        id="mock-tests"
      >
        <div className="about-section_new">
          <div className="about-section-image_new">
            <Lottie animationData={test} key={2}></Lottie>
          </div>
          <div className="about-section-content_new">
            <h2 className="exam-title">Mock Tests and Practice Papers</h2>
            <p className="about-section-description_new">
              Maximize your exam preparation with our mock tests and practice
              papers meticulously crafted to simulate real exam conditions.
              Evaluate your performance, identify your strengths and weaknesses,
              and fine-tune your exam-taking strategy. These invaluable
              resources are designed to enhance your confidence and readiness
              for the actual examination.
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Sci;
