import React, { useState, useRef, useEffect } from "react";
import "./Faq.css";
import axios from "axios";
import emailjs from "@emailjs/browser";
import { AnimatePresence, motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import Teamcard from "../../Components/Team_cards/Teamcard";
import Lottie from "lottie-react";
import faqdata from "../../Assets/Lottie/faq.json";
function Faq({popup,setpop}) {
  const [emailuser, setemail] = useState("");
  const [nameuser, setname] = useState("");
  const [questionuser, setquestion] = useState("");

  const onChange = (e) => {
    setemail(e.target.value);
    // console.log(query);
  };
  const namechange = (e) => {
    setname(e.target.value);
    // console.log(query);
  };
  const questionchange = (e) => {
    setquestion(e.target.value);
    // console.log(query);
  };

  function isValidEmail(email) {
    const emailform_faqat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailform_faqat.test(email);
  }
  const form_faq = useRef();
  const sendEmail = async (e) => {
    e.preventDefault();
    if (isValidEmail(emailuser)) {
      toast.warning("Processin Your Request....", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme:"dark",
        className: "toast-custom-style",
      });

      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const { data } = await axios.post(
          "https://i-science-ser.vercel.app/api/v1/forms/Question",
          {
            name: nameuser,
            email: emailuser,
            question: questionuser,
          },
          config
        );
        console.log(data);
      } catch (error) {
        console.log('error')
        toast.error("Some Error Occured Please Fill The Form Again....", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          // theme:"dark",
          className: "toast-custom-style",
        });
        setname("");
        setemail("");
        setquestion("");
        return;
      }
      await emailjs.sendForm(
        "service_prnvolm",
        "template_wpskgdr",
        form_faq.current,
        "8lJSuYvg8DqMtBcn9"
        )
        .then(
          (result) => {
            console.log(result.text);
            toast.success(
              "Thank you, Your Question has been Submitted will get back to you!",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // theme:"dark",
                className: "toast-custom-style",
              }
            );
          },
          (error) => {
            console.log(error.text);
            toast.error("Sorry!!! , Some Error Occurred, Please Try Again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              // theme:"dark",
              className: "toast-custom-style",
            });
          }
        );
    } else {
      toast.warning("Please Enter a Valid Email and then Retry", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme:"dark",
        className: "toast-custom-style",
      });
    }
    form_faq.current.reset();
    // alert("Submitted!")
    setname("");
    setemail("");
    setquestion("");
  };

const [selected, setSelected] = useState([]);

const toggle = (i) => {
  if (selected.includes(i)) {
    setSelected(selected.filter((item) => item !== i));
  } else {
    setSelected([...selected, i]);
  }
};

useEffect(() => {
  window.scrollTo({
    top: 10,
    behavior: "smooth",
  });
}, []);

  return (
    <>
      <div className="temp"></div>
      <AnimatePresence>
        <section className={popup?"blurify faqsection":"faqsection"} id="mainfaqcontainer">
          <motion.div
            className="starter"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0, delay: 0 } }}
            transition={{ delay: 0, duration: 2 }}
          >
            <h2 className="heading">Help Center </h2>
          </motion.div>

          <div className="sect">
            <motion.div
              initial={{ x: "-100%", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              exit={{
                x: "-100%",
                opacity: 0,
                transition: { duration: 0, delay: 0 },
              }}
              transition={{ duration: 1, delay: 1, ease: "easeOut" }}
            >
              <div className="faq_text sectdiv">
                <h1 className="secondarycolor">FAQs</h1>
                <p>How can we help you?</p>
                <h2>Support</h2>
                <Lottie animationData={faqdata} key={2}></Lottie>
              </div>
            </motion.div>

            <motion.div
              initial={{ x: "100%", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              exit={{
                x: "100%",
                opacity: 0,
                transition: { duration: 0, delay: 0 },
              }}
              transition={{ duration: 1, delay: 1, ease: "easeOut" }}
            >
              <div className="faq_list sectdiv">
  {data.map((item, i) => (
    <div className="item" key={i}>
      <div className="title_faq" onClick={() => toggle(i)}>
        <h3>{item.questionn}</h3>
        <span>
          {selected.includes(i) ? (
           <svg
           xmlns="http://www.w3.org/2000/svg"
           width="16"
           height="16"
           fill="currentColor"
           className="bi bi-chevron-up"
           viewBox="0 0 16 16"
         >
           <path
             fillRule="evenodd"
             d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
           />
         </svg>
          ) : (
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-down"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
          )}
        </span>
      </div>
      <div className={selected.includes(i) ? "content_show" : "content_faq"}>
        {item.answer}
      </div>
    </div>
  ))}
</div>
            </motion.div>
          </div>

          <motion.div
            className="contact"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0, delay: 0 } }}
            transition={{ delay: 0, duration: 2 }}
          >
            <p>Don't have your questions answered?</p>
            <h1 className="contactus secondarycolor">Contact Us</h1>

            <div className="contact_form_faq ">
              <form className="grid_container" ref={form_faq} onSubmit={sendEmail}>
                <div className="contact_flexo">
                  {/* <label className="item1 form_faq_text">
                     Name:
                  </label> */}
                  <input
                    className="item2 inputform_faq"
                    type="text"
                    name="name"
                    placeholder="Name"
                    autoFocus
                    value={nameuser}
                    onChange={namechange}
                  />
                </div>
                <div className="contact_flexo">
                  {/* <label className="item3 form_faq_text">
                     Email:
                  </label> */}
                  <input
                    className="item4 inputform_faq"
                    type="email"
                    name="email"
                    required="true"
                    autoFocus
                    value={emailuser}
                    onChange={onChange}
                    placeholder="Email Address"
                  />
                </div>
                <div className="contact_flexo">
                  {/* <label className="item5 form_faq_text">
                     Question:
                  </label> */}
                  <input
                    className="item6 inputform_faq"
                    type="text"
                    name="message"
                    required="true"
                    placeholder="Your Question"
                    autoFocus
                    value={questionuser}
                    onChange={questionchange}
                  />
                </div>
                <div className="submitform_faq">
                  <input
                    className="item7 inout"
                    type="submit"
                    value="Submit"
                    required="true"
                  />
                  <ToastContainer />
                </div>
              </form>
            </div>
          </motion.div>
        </section>
      </AnimatePresence>
    </>
  );
}
const data = [
  {
    questionn:
      "What subjects are typically covered in 11th and 12th-grade science classes?",
    answer:
      "The standard subjects for 11th and 12th-grade science classes include Physics, Chemistry, and Biology (Botany and Zoology). These classes lay the foundation for further specialization in either Medical (NEET) or Engineering (JEE) fields.",
  },
  {
    questionn:
      "How do JEE and NEET classes differ in terms of preparation and focus areas?",
    answer:
      "JEE (Joint Entrance Examination) focuses on engineering-related subjects like Physics, Chemistry, and Mathematics, whereas NEET (National Eligibility cum Entrance Test) is centered around Biology, including Botany and Zoology. The preparation strategies and emphasis on subjects differ accordingly.",
  },
  {
    questionn:
      "What role do coaching classes play in preparing students for JEE and NEET exams?",
    answer:
      "Coaching classes for JEE and NEET provide structured study plans, expert guidance, and practice materials to help students excel in the competitive exams. They offer specialized coaching for each subject, addressing the unique challenges posed by JEE and NEET syllabi.",
  },
  {
    questionn:
      "How can students effectively manage their time while preparing for 11th, 12th, JEE, and NEET simultaneously?",
    answer:
      "Effective time management involves creating a realistic study schedule, prioritizing subjects based on difficulty, and taking breaks for relaxation. Balancing regular classwork, self-study, and practice tests is essential to succeed in all areas.",
  },
  {
    questionn: "What are the key tips for success in JEE and NEET exams?",
    answer:
      "Success in JEE and NEET exams requires consistent and focused preparation. Key tips include understanding the exam patterns, practicing with previous years question papers, seeking clarification on doubts, and maintaining a healthy study routine.",
  },
  {
    questionn:
      "How do coaching classes adapt their teaching methods to cater to different learning styles?",
    answer:
      "Coaching classes often employ diverse teaching methods, including lectures, interactive sessions, and practical demonstrations to accommodate various learning styles. They may also provide additional resources and personalized assistance to address individual learning preferences.",
  },
];

export default Faq;
