import React, { useState, useEffect } from "react";
import "./Nav.css";
import { Link, useNavigate } from "react-router-dom";
import science from "../../Assets/science-icon.png";
import jee from "../../Assets/jee-icon.png";
import Progressbar from "../Progressbar/Progressbar";
import logo from "../../Assets/logo.png";
import { useLocation } from "react-router-dom";
// import Scrolltop from "../Scrolltop";
// import imagee from "../../Assets/data-label.avif"
const Nav = ({ setpop, popup }) => {
  const location = useLocation();
  let path = location.pathname.toLowerCase();
  const [scrolled, setScrolled] = useState(false);
  // const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const handleLinkClick = () => {
  //   setMobileMenuOpen(false);
  // };
  const navigate = useNavigate();
  const handleLinkClickdirect = () => {
    const mobileMenuCheckbox = document.getElementById("menu-btn");
    mobileMenuCheckbox.checked = false;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // const handleDownload = (imageSrc) => {
  //   const link = document.createElement('a');
  //   link.href = imageSrc;
  //   link.download = 'image.jpg';
  //   link.target = '_blank';
  //   link.click();
  // };
  const handleScroll = () => {
    //scroll a
    const offset = window.scrollY;

    if (offset > 200) {
      setScrolled(true);
      // console.log("set true");
      // console.log(path);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleLinkClickcheck = (className) => {
    // console.log(className);
    // if (window.location.pathname !== '/Services') {
    //   // If the current URL is not "/Services", navigate to "/Services"
    //   navigate('/Services');
    //   const MainfaqSection = document.getElementById(className);
    //   MainfaqSection.scrollIntoView({ behavior: 'smooth' });
    // } else {
    //   // If the current URL is "/Services", scroll to the element with the given className
    //   const MainfaqSection = document.getElementById(className);
    //   MainfaqSection.scrollIntoView({ behavior: 'smooth' });
    // }
    handleLinkClickdirect();
    navigate(`/Services#${className}`);
  };
  const [selectedOption, setSelectedOption] = useState("close-btn");

  const handleRadioChange = (event) => {
    if (
      event.target.id === "showMega2" ||
      event.target.id === "showDrop" ||
      event.target.id === "showDrop3"
    ) {
      setSelectedOption("close-btn");
    } else setSelectedOption(event.target.id);
  };
  return (
    <>
      <nav className={`${scrolled ? "isfixed sticky-header" : ""}`}>
        {selectedOption === "close-btn" && <Progressbar />}
        <div className="wrapper">
          <div className="logo">
            <Link to="/" onClick={() => handleLinkClickdirect()}>
              <img src={logo} className="logoone" alt="IScience"></img>
            </Link>
          </div>
          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" />
          <ul className="nav-links">
            <label htmlFor="close-btn" className="btn close-btn">
              <i className="fas fa-times navi_close"></i>&nbsp;
            </label>
            <li>
              <Link
                to="/Ourteam"
                className="desktop-item"
                onClick={() => handleLinkClickdirect()}
              >
                About Us
              </Link>
              <input
                type="checkbox"
                id="showDrop"
                onChange={handleRadioChange}
              />
              <label htmlFor="showDrop" className="mobile-item">
                About Us
              </label>
              <ul className="drop-menu">
                <li>
                  <Link
                    to="/OurExpertise"
                    onClick={() => handleLinkClickdirect()}
                  >
                    Our Expertises
                  </Link>
                </li>
                <li>
                  <Link onClick={() => handleLinkClickdirect()} to="/Ourteam">
                    Our Team
                  </Link>
                </li>
                <li>
                  <Link
                    to="/BranchGlipmses"
                    onClick={() => handleLinkClickdirect()}
                  >
                    Branch Glimpses
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Prospectus"
                    onClick={() => handleLinkClickdirect()}
                  >
                    Prospectus
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/Results"
                className="desktop-item"
                onClick={() => handleLinkClickdirect()}
              >
                Results
              </Link>
              <input
                type="checkbox"
                onClick={() => {
                  handleLinkClickdirect();
                  navigate("/results");
                }}
                id="showMega2"
                onChange={handleRadioChange}
                checked={selectedOption === "close-btn"}
              />
              <label htmlFor="showMega2" className="mobile-item">
                Results
              </label>
            </li>
            <li>
              <Link to="/Jee" className="desktop-item">
                Category
              </Link>
              <input
                type="checkbox"
                id="showDrop3"
                onChange={handleRadioChange}
                
              />
              <label htmlFor="showDrop3" className="mobile-item">
                Category
              </label>
              <ul className="drop-menu">
                <li>
                  <Link to="/Jee" onClick={() => handleLinkClickdirect()}>
                    <img width="20" height="20" src={jee} alt="Jee" /> JEE
                    Main/Adv.{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/Neet" onClick={() => handleLinkClickdirect()}>
                    <i class="fa-solid fa-stethoscope"></i> NEET
                  </Link>
                </li>
                <li>
                  <Link to="/Science" onClick={() => handleLinkClickdirect()}>
                    <img width="20" height="20" src={science} alt="Science" />
                    Science
                  </Link>
                </li>
                <li>
                  <Link to="/Mhtcet" onClick={() => handleLinkClickdirect()}>
                    <img width="20" height="20" src={jee} alt="Jee" />Mhtcet
                  </Link>
                </li>
              </ul>
            </li>
            <li>
            <Link to="/Our_Branches" onClick={() => handleLinkClickdirect()}>
                Our Branches
            </Link>
            </li>
            <li>
              <Link to="https://iscience.theonlinetests.com" target="_blank" onClick={() => handleLinkClickdirect()}>
                Online Platform
              </Link>
            </li>
            <li>
              <Link to="/Events" onClick={() => handleLinkClickdirect()}>
                Events
              </Link>
            </li>
            <li>
              <Link to="/contactus" onClick={() => handleLinkClickdirect()}>
                Contact us
              </Link>
            </li>
            <li>
              <Link to="/Elearn" onClick={() => handleLinkClickdirect()}>
                E-Learning 
              </Link>
            </li>
          </ul>
          <ul>
            <li className="yewala">
              {/* <Link to="/" className="desktop-item"> */}
              <button
                className="custom-button pulse"
                onClick={() => {
                  setpop(true);
                }}
              >
                Enquire Now
              </button>
              {/* </Link> */}
            </li>
          </ul>
          <label htmlFor="menu-btn" className="btn menu-btn">
            {/* <i className="fas fa-bars"></i>&nbsp;  */}
            <i className="fa-solid fa-bars navi_color"></i>&nbsp;
          </label>
        </div>
      </nav>
    </>
  );
};

export default Nav;
