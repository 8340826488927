import React, { useState } from "react";
import styled from "styled-components";
import imgx from "../../Assets/closeeye.png";
import "./Login.css"
import axios from "axios"
import {toast,ToastContainer} from "react-toastify"
import {useNavigate} from "react-router-dom"
const Wrapper = styled.div`
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
  background-color: #fffff;
`;

const LoginForm = styled.form`
  width: 100%;

  max-width: 400px;
  padding: 20px;
  background-color: #f5f5f;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;

  &:hover,
  &:focus-within {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  @media (width<670px) {
    max-width: 80vw;
  }
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;

  &:hover,
  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #0056b3;
  }
`;
const EyeIcon = styled.span`
 
  cursor: pointer;
`;
const Login = () => {
    const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async(e) => {
    e.preventDefault();
    try{
        const config = {
            headers: {
              "Content-type": "application/json",
            },
          };
        const {data} =await axios.post("https://i-science-ser.vercel.app/api/v1/user/login",{
            email: username,
            password : password
        },config)
        // console.log(data.token);
        localStorage.setItem("token", data.token);
        setPassword("");
        setUsername("");
        navigate("/admin");
    }catch(e){
        toast.error("Sorry!!! , Some Error Occurred, Please Try Again", {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "toast-custom-style",
          });
        console.log(e);
    }
  };

  return (
    <Wrapper>
      <LoginForm onSubmit={handleSubmit}>
        <Label htmlFor="username">Email</Label>
        <Input
          type="email"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <Label htmlFor="password">Password</Label>
        <div className="eye_icon_d">
          <Input
            type={showPassword ? "text" : "password"}
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <EyeIcon onClick={handleTogglePassword}>
            {showPassword ? "👁️" : <img src={imgx} alt="eyeicon" />}
          </EyeIcon>
        </div>
        <Button type="submit">Login</Button>
      </LoginForm>
      <ToastContainer/>
    </Wrapper>
  );
};

export default Login;
