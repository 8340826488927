import React from 'react'
import "./Preloader.css"
const PreLoader = () => {
  return (
   <>
  <div id="page">
        <div id="containernn">
            <div id="ring"></div>
            <div id="ring"></div>
            <div id="ring"></div>
            <div id="ring"></div>
            <div id="h3">loading</div>
        </div>
</div>
   </>
  )
}

export default PreLoader
