import React from "react";
import Slider from "../../Components/Slider/Slider";
import "./Home.css";
import Student_Review from "../../Components/Student_review/Student_Review";
import Cards from "../../Components/Cards/Cards";
import Bene from "../../Components/Why_us/Choose";
import Lottie from "lottie-react";
import test from "../../Assets/Lottie/online_test.json";
import LiveGreetings from "../../Components/Festival/LiveGreeting";
import Faq from "../../Components/FAQ/Faq";
import Timetable from "../../Components/Timetable/Time";
const Home = ({ popup, setpop }) => {
  return (
    <>
      <div className={popup ? "homepage blurify" : "homepage"}>
        <LiveGreetings/>
        <Slider />
        <Bene />
        <Cards />
        <Timetable/>
        <Student_Review />
        <Faq/>
      </div>
    </>
  );
};

export default Home;
