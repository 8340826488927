import React, { useState, useRef, useEffect } from "react";

import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import emailjs from "@emailjs/browser";
import Lottie from "lottie-react";
import animationdata from "../../Assets/Lottie/contactus.json";
const Contactus = ({ popup, setpopup }) => {
  const [name, setName] = useState("");
  // const [activechat, setchat] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [interestedService, setInterestedService] = useState("");
  const [location, setlocation] = useState("");
  const secondform = useRef();

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\d{10}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const handleSubmit = async () => {
    if (!isValidPhoneNumber(phone)) {
      toast.warning("Please Enter a Phone number and then Retry", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme:"dark",
        className: "toast-custom-style",
      });
      secondform.current.reset();
      return;
    }
    if (isValidEmail(email)) {
      toast.warning("Processin Your Request....", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-custom-style",
      });

      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const { data } = await axios.post(
          "https://i-science-ser.vercel.app/api/v1/forms/Bookchat",
          {
            name: name,
            email: email,
            phoneno: phone,
            courses: interestedService,
            question: message,
            location: location,
          },
          config
        );
      } catch (error) {
        toast.warning({
          title: "Error Occured!",
          description: error.response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
          className: "toast-custom-style",
        });
        setName("");
        setEmail("");
        setInterestedService("");
        setPhone("");
        setMessage("");
        setlocation("");
        setCaptchaText("");
        return;
      }
      await emailjs
        .sendForm(
          "service_prnvolm",
          "template_wpskgdr",
          secondform.current,
          "8lJSuYvg8DqMtBcn9"
        )
        .then(
          (result) => {
            toast.success(
              "Thank you, Your Details has been Submitted will get back to you!",
              {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: "toast-custom-style",
              }
            );
          },
          (error) => {
            console.log(error.text);
            toast.error("Sorry!!! , Some Error Occurred, Please Try Again", {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              className: "toast-custom-style",
            });
          }
        );
    } else {
      toast.warning("Please Enter a Valid Email and then Retry", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme:"dark",
        className: "toast-custom-style",
      });
    }
    secondform.current.reset();
    // alert("Submitted!")
    setName("");
    setEmail("");
    setInterestedService("");
    setPhone("");
    setMessage("");
    setlocation("");
    setCaptchaText("");
  };

  function isValidEmail(email) {
    const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailFormat.test(email);
  }

  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const canvasRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 1,
      behavior: "smooth",
    });
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    initializeCaptcha(ctx);
  }, []);

  const generateRandomChar = (min, max) =>
    String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min));

  const generateCaptchaText = () => {
    let captcha = "";
    for (let i = 0; i < 2; i++) {
      captcha += generateRandomChar(65, 90);
      captcha += generateRandomChar(97, 122);
      captcha += generateRandomChar(48, 57);
    }
    return captcha
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
  };

  const drawCaptchaOnCanvas = (ctx, captcha) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const textColors = ["rgb(0,0,0)", "#008080", "#FF6347", "#4B0082"];
    const letterSpace = 150 / captcha.length;
    for (let i = 0; i < captcha.length; i++) {
      const xInitialSpace = 25;
      ctx.font = "20px Roboto Mono";
      ctx.fillStyle = textColors[Math.floor(Math.random() * 2)];
      ctx.fillText(
        captcha[i],
        xInitialSpace + i * letterSpace,

        // Randomize Y position slightly
        Math.floor(Math.random() * 16 + 25),
        100
      );
    }
  };

  const initializeCaptcha = (ctx) => {
    setUserInput("");
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha);
    drawCaptchaOnCanvas(ctx, newCaptcha);
  };

  const handleUserInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleCaptchaSubmit = (e) => {
    e.preventDefault();
    if (userInput.toLowerCase() === captchaText.toLowerCase()) {
      handleSubmit();
    } else {
      //   // Incorrect captcha, reset and display error
      //   alert("Incorrect captcha. Please try again.");
      toast.error("Please Enter a proper Captcha and then Retry", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme:"dark",
        className: "toast-custom-style",
      });
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      initializeCaptcha(ctx);
    }
  };
  return (
    <>
      <div className={popup ? "whole_contactus blurify" : "whole_contactus"}>
        <div className="left_contactus">
          <div className="animations_c">
            <Lottie
              animationData={animationdata}
              style={{ height: 320 }}
              key={3}
            />
          </div>
          <div className="title-lockup">
            <h2 className="heading-md">Explore Our Classes</h2>
            <div className="v2-subtitle">
              Welcome to our diverse range of science classes! Whether you're a
              beginner or an enthusiast, our classes offer engaging content and
              hands-on learning experiences. Join us on a journey of discovery
              and knowledge.
            </div>
            <p className="v2-subtitle mb-1-5">
              Need assistance or have questions about our classes?
            </p>
            <div className="ancher">
              Contact us : <nbsp />
              <a href="mailto:info@yourclasseswebsite.com">
                info@yourclasseswebsite.com
              </a>
            </div>

            <p>
              <br />
              Explore the world of science with us at our convenient locations:
              <br />
              <br />
              <strong>Main Office:</strong>
              <p>
                19, Ground Floor, Nandprem Bldng., Nehru Road, Near Parle Book
                Depot, Vile Parle (East) Mumbai-57
              </p>
              <br />
              <strong>Other Office:</strong>
              <p>
                 403, Kesarinath Bldng, Near Filmistan
                Studio, S.V Road, Goregaon (West), Mumbai
              </p>
            </p>
          </div>
        </div>
        <div className="right_contactus">
          <div className="form-container">
            
            <div className="form-content">
              <form
                className="send-message-form"
                ref={secondform}
                onSubmit={handleCaptchaSubmit}
              >
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required={true}
                />

                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required={true}
                />

                <label htmlFor="phone">Phone Number*</label>
                <input
                  type="tel"
                  id="phone"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required={true}
                />
                <label htmlFor="location">Location</label>
                <input
                  type="text"
                  id="location"
                  placeholder="Class Area your looking for"
                  value={location}
                  onChange={(e) => setlocation(e.target.value)}
                />

                <label htmlFor="interestedcourse">Interested Course*</label>
                <select
                  id="interestedService"
                  value={interestedService}
                  onChange={(e) => setInterestedService(e.target.value)}
                  required={true}
                >
                  <option value="">Select Interested Course</option>
                  <option value="11th or 12th Science">
                    11th or 12th Science
                  </option>
                  <option value="Jee Mains/Adv.">Jee Mains/Adv.</option>
                  <option value="Neet">Neet</option>
                  <option value="Mhcet">Mhcet</option>
                </select>

                <label htmlFor="message">Question/Info*</label>
                <textarea
                  id="message"
                  placeholder="How can we help?"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>

                <canvas ref={canvasRef} width="200" height="40"></canvas>
                <input
                  type="text"
                  id="captcha-input"
                  placeholder="Enter the Captcha in the image"
                  value={userInput}
                  onChange={handleUserInputChange}
                />
                <div className="btn_submit_full">
                  <button type="submit">Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Contactus;
