import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import data from "./festival.json";
import "./festival.css";

const LiveGreetings = () => {
  const [currentFestival, setCurrentFestival] = useState("");
  const [imp,setimp]=useState(false);

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();
        const holidays = data.items;

        console.log("Current Date:", currentMonth, currentDay);

        const todayHoliday = holidays.find((holiday) => {
          const holidayDate = new Date(holiday.start.date);
          const enddate=new Date(holiday.end.date);
          console.log("Holiday Date:", holidayDate.getMonth() + 1, holidayDate.getDate());
          return (
            holidayDate.getMonth() + 1 == currentMonth &&
            holidayDate.getDate() <= currentDay &&
            currentDay<=enddate.getDate()
          );
        });

        console.log("Today's Holiday:", todayHoliday);

        if (todayHoliday) {
          if(todayHoliday.kind==="upd")setimp(true);
          else setimp(false);
          setCurrentFestival(todayHoliday.summary);

          // toast.dark(
          //   `Happy ${todayHoliday.summary}!`,
          //   {
          //     autoClose: false,
          //     closeButton: "Cancel",
          //     className: "custom_css",
          //     onClose: () => setCurrentFestival(""),
          //   }
          // );
        } else {
          setCurrentFestival("");
        }
      } catch (error) {
        console.error("Error fetching holidays:", error);
      }
    };

    fetchHolidays();
  }, []);

  return (
    <div>
      <div className={`festival_container ${imp?"show":"none"}`}>
        {imp && <p>{currentFestival}!</p>}
      </div>
      <ToastContainer />
    </div>
  );
};

export default LiveGreetings;
