import React, { useState, useEffect } from "react";
import YouTubeVideoCard from "./Youtube";
import a from "../../Assets/closeeye.png";
const Elearn = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    // Fetch video data from an API endpoint
    fetch("https://i-science-ser.vercel.app/api/v1/forms/getallvideo")
      .then((response) => response.json())
      .then((data) => setVideos(data))
      .catch((error) => console.error("Error fetching videos:", error));
  }, []);

  return (
    <div className="app">
      
      {/* {videos.map(video => ( */}
      {(videos.length > 0)
      ?
      <>
      <div className="youtube-video-cards-container">
        {videos.map((video) => (
          <YouTubeVideoCard
            key={video.vedeoid}
            videoId={video.videoid}
            title={video.title}
            description={video.description}
          />
          ))}
      </div>
          </>:<><h1 className="heading ">Coming Soon ......</h1></>
        }
      {/* ))} */}
    </div>
  );
};

export default Elearn;
