import React, { useState, useEffect } from 'react';
import './arrow.css'; 

const UpwardsArrow = () => {
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
 
  return (
    <div>
      {showArrow && (
        <div className="upwards-arrow" onClick={scrollToTop}>
          &#8593; 
        </div>
      )}
    </div>
  );
};

export default UpwardsArrow;