import React, { useState, useRef } from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
// import logo from "../../Assets/logo192.png";
import waves from "../../Assets/wavesOpacity.svg";
const Footer = () => {
  const formemail = useRef();
  const moveBy = () => {
    window.scrollTo({ top: 0 });
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="whole_curves">
        <img src={waves} alt="waves" />
      </div>
      <footer className="footer">
        <div className="containerx">
          <div className="rowx">
            <div className="footer-col get_help">
              {/* <img src={logo} alt="company_logo"></img> */}
              <h4>I-Science Tutorials</h4>
              <ul>
                <li>
                  <Link to="/OurTeam">Our Team</Link>
                </li>
                <li>
                  <Link to="/BranchGlipmses">Branch Glimpses</Link>
                </li>
                <li>
                  <Link to="/OurExpertise">Our Expertise</Link>
                </li>
                <li>
                  <Link to="/Prospectus">Prospectus</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col ">
              <h4>Categories</h4>
              <ul>
                <li>
                  <Link to="/Jee">JEE Mains/Adv.</Link>
                </li>
                <li>
                  <Link to="/Neet">NEET</Link>
                </li>
                <li>
                  <Link to="/Science">SCIENCE</Link>
                </li>
                <li>
                  <Link to="/Mhtcet">MHTCET</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col get_help">
              <h4>Wants to Contact us?</h4>
              <ul>
                <li>
                  <Link to="/Contactus">Contact Us</Link>
                </li>
                <li>
                  <Link  to="/faq">
                    Any Questions?
                  </Link>
                </li>
               
              </ul>
            </div>
            <div className="footer-col get_help">
              <h4>Resources</h4>
              <ul>
                <li>
                  <Link to="/Results">Results</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                
                <li>
                  <Link to="/Events">Events</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>follow us</h4>
              <div className="social-links">
                <Link to="https://www.facebook.com/iscienceprivatetutorials">
                  {/* <i className="fab fa-linkedin-in"></i> */}
                  <i class="fa-brands fa-facebook-f"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
