import React from 'react';
import './time.css'; // Import your CSS file for styling

const Timetable = () => {
  return (
    <>
          <h1 className="heading">Regular Batch Timetable </h1>
    <div className="timetable">
      <div className="header">
        <div className="cell">Standard</div>
        <div className="cell">Timings</div>
        <div className="cell">Monday to Sunday</div>
      </div>
      <div className="row">
        <div className="cell">11th</div>
        <div className="cell">7:30 am - 10:30 am</div>
        <div className="cell">THEORY+JEE/NEET/MHT-CET</div>
      </div>
      <div className="row">
        <div className="cell">12th</div>
        <div className="cell">7:30 am - 10:30 am</div>
        <div className="cell">THEORY+JEE/NEET/MHT-CET</div>
      </div>
    </div>

    <h1 className="heading">Integrated Timetable </h1>
    <div className="timetable">
      <div className="header">
        <div className="cell">Standard</div>
        <div className="cell">Timings</div>
        <div className="cell">Monday to Sunday</div>
      </div>
      <div className="row">
        <div className="cell">11th</div>
        <div className="cell">7:30 am - 2:30 am</div>
        <div className="cell">THEORY+JEE/NEET/MHT-CET</div>
      </div>
      <div className="row">
        <div className="cell">12th</div>
        <div className="cell">7:30 am - 2:30 am</div>
        <div className="cell">THEORY+JEE/NEET/MHT-CET</div>
      </div>
</div>
      <h1 className="heading">Test Schedule  </h1>
      <div className="timetablex">
      <div className="header">
        <div className="cell">Test</div>
        <div className="cell">Mode </div>
        <div className="cell">From</div>
        <div className="cell">Cycle</div>
      </div>
      <div className="row">
        <div className="cell b">11 th, 12 th (Chapter test)</div>
        <div className="cell">Offline</div>
        <div className="cell">Classroom</div>
        <div className="cell">Weekly </div>
      </div>
      <div className="row">
        <div className="cell b">Jee Mains(Chapter Wise) </div>
        <div className="cell">Online</div>
        <div className="cell">Classroom</div>
        <div className="cell">Weekly</div>
      </div>
      <div className="row">
        <div className="cell b">Jee Mains(PCM Combo) </div>
        <div className="cell">Online</div>
        <div className="cell">Classroom</div>
        <div className="cell">Monthly</div>
      </div>
      <div className="row">
        <div className="cell b">Neet(Chapter Wise) </div>
        <div className="cell">Offline</div>
        <div className="cell">Classroom</div>
        <div className="cell">Weekly</div>
      </div>
      <div className="row">
        <div className="cell b">Viva/Assignment</div>
        <div className="cell">Offline</div>
        <div className="cell">Classroom</div>
        <div className="cell">Weekly/Daily</div>
      </div>
      
    </div>

    
    </>
  );
}

export default Timetable;
