import React from 'react';
import "./Yt.css"; // Import the CSS file for styling

const YouTubeVideoCard = ({ videoId, title, description }) => {
  // Construct the YouTube embed URL
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    
    <div className="youtube-video-card">
      <div className="youtube-video-wrapper">
        <iframe
          className="youtube-video-iframe"
          src={embedUrl}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="youtube-video-details">
        <h2 className="video-title">{title}</h2>
        <p className="video-description">{description}</p>
      </div>
    </div>
  );
};

export default YouTubeVideoCard;
