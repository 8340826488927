import React,{useEffect} from "react";
import "./iska.css"; 
import jee from "../../Assets/Pdf/jee.jpg"
import neet from "../../Assets/Pdf/neet.jpg"
import rep from "../../Assets/Pdf/repeaters.png"
const ProspectusPage = ({popup,setpop}) => {
  useEffect(() => {
    window.scrollTo({
      top: 1,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className={popup?"blurify":""}>
    <div className="prospectus-page">
      <div className="prospectus-info">
        <h2>What is a Prospectus?</h2>
        <p>
          A prospectus is a document that provides information about a school,
          college, or educational program. It includes details about the courses,
          faculty, facilities, and other important information that helps students
          make informed decisions about their education.
        </p>
      </div>
    </div>

      <div className="download-boxes">
        <div className="prospectus-box">
          <img src={neet} alt="NEET Prospectus" />
          <h3>NEET Batch Prospectus</h3>
          <p>Learn more about our NEET batch and download the prospectus.</p>
          <a href="./pros.pdf" className="download-button" download>
            Download Pdf
          </a>
        </div>
        <div className="prospectus-box">
          <img src={jee} alt="JEE Prospectus" />
          <h3>JEE Batch Prospectus</h3>
          <p>Explore details of our JEE batch and download the prospectus.</p>
          <a href="./pros.pdf" className="download-button" download>
            Download Pdf
          </a>
        </div>
      </div>
      </div>
  );
};

export default ProspectusPage;
