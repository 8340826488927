import React, { useMemo, useState, useEffect } from "react";
import "./Table.css";
import { useTable } from "react-table";

function List({ fdata, act }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (fdata) {
      const newData = fdata.filter((team) =>
        team.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(newData);
    }
  }, [fdata, searchTerm]);

  const dynamicColumns = useMemo(() => {
    let columns = [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Question",
        accessor: "question",
      },
    ];

    if (act === "contact") {
      columns.push({
        Header: "Phone Number",
        accessor: "phoneno",
      });
      columns.push({
        Header: "Courses",
        accessor: "courses",
      });
    }

    if (act === "enquiry") {
      columns.push({
        Header: "Location",
        accessor: "location",
      });
      columns.push({
        Header: "Phone Number",
        accessor: "phoneno",
      });
      columns.push({
        Header: "Courses",
        accessor: "courses",
      });
    }

    return columns;
  }, [act]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: dynamicColumns, data: filteredData });

  return (
    <div className="App">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {filteredData.length === 0 && (
        <h2 className="no-teams-message">
          No data found with the given search term.
        </h2>
      )}
      {filteredData.length > 0 && (
        <div className="containert">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}> {cell.render("Cell")} </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default List;
