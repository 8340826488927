import React,{useState,useEffect} from 'react';
import './App.css';
import { Route, Routes } from "react-router-dom";
import Home from './Pages/Home/Home';
import About from './Pages/About_us/About';
import Error from './Pages/Error/Error';
import Nav from './Components/Navbar/Nav';
import Footer from './Components/Footer/Footer';
import PreLoader from './Components/Preloader/PreLoader';
import UpwardsArrow from './Components/Scrolltotop';
import Enquireus from './Pages/Enquireus/Enquire';
import BranchGlimpses from './Components/Collage/Collage';
import ProspectusPage from './Pages/Prospectus/Prospectus';
import Contactus from './Pages/Contact/Contactus';
import Faq from "./Components/FAQ/Faq"
import Login from "./Components/Login/Login.jsx"
import Admin from './Pages/Admin/Admin.jsx';
import OurAbout from './Components/OurTeam/Team.jsx';
import Flowchart from './Components/Flowchart/Flowchart.jsx';
import Result from './Pages/Results/Result.jsx';
import { useLocation } from 'react-router-dom';
import Course from './Components/Courses/Courses.jsx';
import Neet from './Components/Courses/Neet.jsx';
import Mh from './Components/Courses/Mhtcet.jsx';
import Sci from './Components/Courses/Science.jsx';
import Branches from './Pages/Branches/Branches.jsx';
import Events from './Components/Collage/Events.jsx';
import Elearn from './Components/ELearn/Elearn.jsx';
function App() {
  const [load, setload] = useState(false);
  const history = useLocation();
  const currentPath = history.pathname.toLowerCase();
  const [popup, setpop] = useState(false);
  useEffect(() => {
    setload(true);
    setTimeout(() => {
      setload(false);
    },2500);
    // console.log(popup);
    // console.log(currentPath)
  }, []);
 

  return (
    load ? (
      <PreLoader/>
    ) : (
      <div className={popup?"App isactive":"App"}>
      <UpwardsArrow/>
      <Nav popup={popup} setpop={setpop}/>
      <Enquireus popup={popup} setpop={setpop}/>
      <Routes>
        <Route path="/" element={<Home popup={popup} setpop={setpop}/>}/>
        <Route path="/about" element={<About popup={popup} setpop={setpop}/>}/>
        <Route path="/BranchGlipmses" element={<BranchGlimpses popup={popup} setpop={setpop}/>}/>
        <Route path="/Prospectus" element={<ProspectusPage popup={popup} setpop={setpop}/>}/>
        <Route path="/Contactus" element={<Contactus popup={popup} setpop={setpop}/>}/>
        <Route path="/Faq" element={<Faq popup={popup} setpop={setpop}/>}/>
        <Route path="/Login" element={<Login popup={popup} setpop={setpop}/>}/>
        <Route path="/Admin" element={<Admin popup={popup} setpop={setpop}/>}/>
        <Route path="/Ourteam" element={<OurAbout popup={popup} setpop={setpop}/>}/>
        <Route path="/OurExpertise" element={<Flowchart popup={popup} setpop={setpop}/>}/>
        <Route path="/Results" element={<Result popup={popup} setpop={setpop}/>}/>
        <Route path="/Jee" element={<Course popup={popup} setpop={setpop}/>}/>
        <Route path="/Neet" element={<Neet popup={popup} setpop={setpop}/>}/>
        <Route path="/Science" element={<Sci popup={popup} setpop={setpop}/>}/>
        <Route path="/Mhtcet" element={<Mh popup={popup} setpop={setpop}/>}/>
        <Route path="/Our_Branches" element={<Branches popup={popup} setpop={setpop}/>}/>
        <Route path="/Events" element={<Events popup={popup} setpop={setpop}/>}/>
        <Route path="/Elearn" element={<Elearn popup={popup} setpop={setpop}/>}/>
        <Route path="*" element={<Error popup={popup} setpop={setpop}/>}></Route>
      </Routes>
      {currentPath !== '/results'&& <Footer/>}
    </div>
  )
  );
}

export default App;
