// Filename: App.js

import React, { useRef } from "react";
import Contactus from "../../Components/Form/Form";
import "./Enquire.css";
export default function Enquireus({ popup, setpop }) {
  const form = useRef();
  const closeit = (e) => {
    if (e.target === form.current) setpop(false);
  };
  return (
    <>
      <div className="temp"></div>
      {popup && (
        <div className="modal">
          <div className="content" ref={form} onClick={closeit}>
            <Contactus setpop={setpop} popup={popup} />
          </div>
        </div>
      )}
    </>
  );
}
