import React,{useState,useEffect} from 'react'

const Progressbar = () => {
    const [scrollPercentage, setScrollPercentage] = useState(0);
    
  useEffect(()=>{
    const handleScroll = ()=>{
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollY = window.scrollY;
        const scrollPercent = (scrollY / (documentHeight - windowHeight))*100 
        setScrollPercentage(scrollPercent)
        // console.log(scrollPercent)
    }
    window.addEventListener("scroll", handleScroll);
    return()=>{
        window.removeEventListener("scroll", handleScroll)
    }
  },[])
  return (
    <div >
      <div
      id="progress-container"
      style={{
        height: "6px",
        width: "100%",
        backgroundColor: "#d1dced",
        position: "fixed",
      }}
    >
      <div
        className="progress-fill"
        style={{
          height: "100%",
          backgroundColor: "var(--primary-color)",
          width: `${scrollPercentage}%`,
        }}
      >
       
      </div>
      </div>
    </div>
  )
}

export default Progressbar
