import React, { useState } from "react";
import "./Cards.css";
import jeeMainImage from "../../Assets/Pdf/jee.jpg";
import neetImage from "../../Assets/NEET-logo.jpg";
import scienceImage from "../../Assets/Pdf/hscscience.jpg";
import kvpyImage from "../../Assets/Pdf/kvpy.png";
import ndaImage from "../../Assets/Pdf/nda.webp";
import mhcetImage from "../../Assets/Pdf/mhcet.jpg";
import { Link, useNavigate } from "react-router-dom";

const Cards = () => {
  const [activeTab, setActiveTab] = useState("vileparle");
  const navigate = useNavigate();
  
  const handleLinkClick = (className) => {
    navigate(`/Services#${className}`);
  };

  const setter=(val)=>{
    setActiveTab(val);
    
  }
  

  const examsData = [
    {
      title: "JEE Main",
      image: jeeMainImage,
      description:
        "Prepare for the Joint Entrance Examination (JEE) Main, which is the gateway for admission into various engineering colleges in India. Our comprehensive coaching will help you excel in the exam.",
      link: "jee",
      branch: ["vileparle", "goregaon", "borivali"],
    },
    {
      title: "NEET",
      image: neetImage,
      description:
        "Get ready for the National Eligibility cum Entrance Test (NEET) for admission to medical courses in India. Our coaching program focuses on helping you succeed in this highly competitive medical entrance exam.",
      link: "neet",
      branch: ["vileparle", "goregaon", "borivali"],
    },
    {
      title: "Science 11th and 12th",
      image: scienceImage,
      description:
        "Ace your 11th and 12th-grade science subjects with our specialized coaching. Strengthen your foundation and gain a deep understanding of physics, chemistry, and biology to excel in your board exams and entrance tests.",
      link: "science",
      branch: ["vileparle", "goregaon", "borivali"],
    },
    {
      title: "KVPY",
      image: kvpyImage,
      description:
        "Prepare for the Kishore Vaigyanik Protsahan Yojana (KVPY) exam, a national-level scholarship program for students interested in pursuing a career in science. Our coaching will guide you through the preparation process.",
      link: "kvpy-details",
      branch: ["vileparle", "goregaon", "borivali"],
    },
    {
      title: "NDA",
      image: ndaImage,
      description:
        "Join our coaching program to prepare for the National Defence Academy (NDA) entrance exam. Develop the skills and knowledge required to succeed in the written and interview rounds for a career in defense services.",
      link: "nda",
      branch: [],
    },
    {
      title: "MHTCET",
      image: mhcetImage,
      description:
        "Gear up for the Maharashtra Common Entrance Test (MHCET) for admission to various professional courses in the state. Our coaching will help you navigate the syllabus and perform well in this state-level exam.",
      link: "mhtcet",
      branch: ["borivali","vileparle", "goregaon"],
    },
  ];
  const shuffledData = [...examsData].sort(() => Math.random() - 0.5);

  const filteredData = shuffledData.filter((exam) =>
    exam.branch.includes(activeTab)
  );


  return (
    <>
      <div className="gapi"></div>
      <div>
        <h1 className="heading">Our Courses</h1>
      </div>

      <div className="loop_tabs">
        <button
          className={`loop_btn ${
            activeTab === "vileparle" ? "current" : ""
          }`}
          onClick={() => setActiveTab("vileparle")}
        >
          Vile Parle
        </button>
        <button
          className={`loop_btn ${
            activeTab === "goregaon" ? "current" : ""
          }`}
          onClick={() => setActiveTab("goregaon")}
        >
          Goregaon
        </button>
        <button
          className={`loop_btn ${
            activeTab === "borivali" ? "current" : ""
          }`}
          onClick={() => setActiveTab("borivali")}
        >
          Borivali
        </button>
      </div>

      <div className="whole_courses">
        <div className="all_cards">
          <div className="card-category-2">
            <br />
            <ul>
              {filteredData.map((exam) => (
                <li key={exam.title}>
                  <div className="img-card iCard-style1">
                    <div className="card-content">
                      <div className={exam.title==="NEET"?"card-image yahi":"card-image"}>
                        <img src={exam.image} alt={exam.title} />
                      </div>

                      <div className="card-text">
                        <h3>{exam.title}</h3>
                        <p>{exam.description}</p>
                      </div>
                    </div>

                    <div className="card-link">
                      <Link
                        to={`/${exam.link}`}
                        onClick={() => handleLinkClick(exam.link)}
                      >
                        <span className="read_full">
                          Read More{" "}
                          <i
                            className="fa-sharp fa-solid fa-arrow-right fa-bounce fa-xl"
                            style={{ color: "#2869bd", fontSize: 27 }}
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <br />
        </div>
      </div>
    </>
  );
};

export default Cards;
