import React ,{useEffect} from "react";
import "./flowchart.css";
import { motion } from "framer-motion";
import logo from "../../Assets/logo_circle.png";
const Flowchart = ({popup}) => {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, []);
  return (
    <div className={popup?"blurify":""}>
    <h1 className="page-title">Our Expertises and Techniques</h1>
      <div className="eventContainer">
        <div className="eventTimeLine">
          <div className="upcomingeventsCircle">
            <img src={logo} alt="logo" />
          </div>

          <ul>
            <li>
              <motion.div
                initial={{ x: "-100%", opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                exit={{ x: "-100%", opacity: 0, transition: { duration: 0, delay: 0 } }}
                transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
              >
                <div className="motionaldivision">
                  <div className="eventTimeLineContent" key={1}>
                    <div className="eventRight">
                      <div>
                        <h1>Expert Team of Teachers</h1>
                      </div>
                      <div>
                        <p>
                          Our team comprises qualified, experienced, understanding, and supportive teachers who inspire understanding. They bring a wealth of knowledge and expertise to guide students in their academic journey.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </li>

            <li>
              <motion.div
                initial={{ x: "100%", opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                exit={{ x: "100%", opacity: 0, transition: { duration: 0, delay: 0 } }}
                transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
              >
                <div className="motionaldivision">
                  <div className="eventTimeLineContent" key={2}>
                    <div className="eventLeft">
                      <h1>2400+ Hours of Classroom Coaching</h1>
                      <p>
                        Our classroom coaching exceeds industry standards, providing students with extensive learning opportunities. With over 2400 hours of classes, we ensure a comprehensive and immersive learning experience.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </li>

            <li>
              <motion.div
                initial={{ x: "-100%", opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                exit={{ x: "-100%", opacity: 0, transition: { duration: 0, delay: 0 } }}
                transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
              >
                <div className="motionaldivision">
                  <div className="eventTimeLineContent" key={3}>
                    <div className="eventRight">
                      <h1>Comprehensive & Precise Notes</h1>
                      <p>
                        Authentic, easy-to-understand study materials are provided for all subjects. Our notes are meticulously crafted to offer a comprehensive understanding of the curriculum, aiding students in their preparation.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </li>

            <li>
              <motion.div
                initial={{ x: "100%", opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                exit={{ x: "100%", opacity: 0, transition: { duration: 0, delay: 0 } }}
                transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
              >
                <div className="motionaldivision">
                  <div className="eventTimeLineContent" key={4}>
                    <div className="eventLeft">
                      <h1>250+ Tests & 25 Prelims</h1>
                      <p>
                        We offer a comprehensive testing approach with 250+ tests, including topic-wise tests, a combination of chapter tests, and 24 permanent exhaustive test series for NEET & JEE Main. These assessments ensure thorough preparation and mastery of subjects.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </li>

            <li>
              <motion.div
                initial={{ x: "-100%", opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                exit={{ x: "-100%", opacity: 0, transition: { duration: 0, delay: 0 } }}
                transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
              >
                <div className="motionaldivision">
                  <div className="eventTimeLineContent" key={5}>
                    <div className="eventRight">
                      <h1>Reference Books Library & Study Room</h1>
                      <p>
                        Our reference books library and study room provide a resourceful environment for students. Access to a variety of reference materials enhances the learning experience and promotes a focused study atmosphere.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </li>

            <li>
              <motion.div
                initial={{ x: "100%", opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                exit={{ x: "100%", opacity: 0, transition: { duration: 0, delay: 0 } }}
                transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
              >
                <div className="motionaldivision">
                  <div className="eventTimeLineContent" key={6}>
                    <div className="eventLeft">
                      <h1>Result-Oriented Perfect Planning</h1>
                      <p>
                        Our result-oriented perfect planning involves a customized planning system for every student. We guide, monitor, and push students to achieve their targets by developing a strategic and effective study plan.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </li>

            <li>
              <motion.div
                initial={{ x: "-100%", opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                exit={{ x: "-100%", opacity: 0, transition: { duration: 0, delay: 0 } }}
                transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
              >
                <div className="motionaldivision">
                  <div className="eventTimeLineContent" key={7}>
                    <div className="eventRight">
                      <h1>Customized Planning System</h1>
                      <p>
                        We provide a customized planning system for every student to guide, monitor, and push them to achieve their targets. Our personalized approach ensures that students receive the support and guidance needed to succeed in their academic endeavors.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Flowchart;
