import React from "react";
import "./Branch.css";
import imgg from "../../Assets/Branch/vileparle3.jpg";
import gore from "../../Assets/Branch/vileparle2.jpg"
const Branches = ({popup}) => {
  return (
    <div className={popup?"blurify":""}>
      <div className="container_branch">
        <h1 className="name_branch">
          <span>O</span>
          <span>u</span>
          <span>r</span>
          <span> </span>
          &nbsp;
          <span>b</span>
          <span>r</span>
          <span className="blink_branch">a</span>
          <span>n</span>
          <span>c</span>
          <span className="blink2_branch">h</span>
          <span>e</span>
          <span className="hang_branch">s</span>
        </h1>
      </div>
      <div className="whole_b">
        <div className="branches-card">
          <div className="branch-card">
            <h3>Head Office</h3>
            <img src={imgg} alt="Head Office" className="branch-image" />
            <p>
              <strong>Location:</strong> Vile Parle (East), Mumbai
            </p>
            <p>
              <strong>Address:</strong> 19, Ground Floor, Nandprem Bldng., Nehru
              Road,<br></br>Opp. Parle Book Depot, Near Station , Vile Parle (East) Mumbai-57
            </p>
            <p>
              <strong>Contact No:</strong> 7208740122 / 9136043499
            </p>
            <a
              href="https://maps.app.goo.gl/EXWDMs5WHwSo1Wta8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="directions-btn">Get Directions</button>
            </a>
          </div>
        </div>

        <div className="branches-card">
          <div className="branch-card">
            <h3>Goregaon Centre</h3>
            <img src={gore} alt="Head Office" className="branch-image" />
            <p>
              <strong>Location:</strong> Goregaon (West), Mumbai
            </p>
            <p>
              <strong>Address:</strong> 403, Kesarinath Bldng, Near Filmistan
              Studio, S.V Road, <br></br>Near Station Goregaon (West), Mumbai-104
            </p>
            <p>
              <strong>Contact No:</strong> 8286439372 / 7777097629
            </p>
            <a
              href="https://maps.app.goo.gl/8qrC14ui9jS1j9d47"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="directions-btn">Get Directions</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Branches;
