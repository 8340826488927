import React, { useState, useEffect } from "react";
import "./Admin.css";

import  getYouTubeID from 'get-youtube-id';

import { useNavigate } from "react-router-dom";
import List from "./Table";
import axios from "axios";
import {toast,ToastContainer} from "react-toastify"
const Admin = () => {
  const navigate = useNavigate();
  const [act, setact] = useState("question");
  const [islogin, setislogin] = useState(false);
  const [data, setdata] = useState([]);
  const [loading, isl] = useState(false);

  const [desc, setdesc] = useState("");
  const [url, seturl] = useState("");
  const [title,settitle]=useState("")
//   const extractYouTubeId = (url) => {

     
// let id = getYouTubeID(url);
// return id;
    // let a=1;
    // let ans="";
    // for(let i=1;i<url.length;i++){
    //   if(url[i-1]==="v" && url[i]!=="=" || a==2){
    //     console.log(url);
    //     if(a==2)ans+=url[i];
    //     a=2;
    //   }

    // }
    // console.log(ans);
    // return ans;
    // const regex = /[?&]v=([^?&]+)/;
    // const match = url.match(regex);
  
    // if (match && match[1]) {
    //   return match[1];

    // } else {
    //   return null;
    // }
  // };
  const extractYouTubeId = (url) => {
    try {
      const params = new URLSearchParams(new URL(url).search);
      const videoId = params.get("v");
      return videoId;
    } catch (error) {
      console.error("Error extracting YouTube video ID:", error);
      return null;
    }
  };
  // const extractYouTubeId = async(url) => {
  //   try {
  //     const youtubeUrl = new URL(url);
  //     const searchParams = youtubeUrl.searchParams;
  //     const videoId = searchParams.get('v');
  //     console.log(videoId);
  //   } catch (error) {
  //     console.error('Error extracting YouTube video ID:', error);
  //     return null;
  //   }
  // };
  const getdata = async () => {
    try {
      const userInfoString = localStorage.getItem("token");
      const userInfo = userInfoString;
      const token = userInfo;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let yaha = "";
      if (act === "question") yaha = "getallquestion";
      else if (act === "enquiry") yaha = "getallbookchat";
      else if(act==="contact") yaha = "getallcontact";
      else{ isl(false); return ;}
      await fetch(`https://i-science-ser.vercel.app/api/v1/forms/${yaha}`, {
        headers: headers,
      })
        .then((res) => res.json())
        .then((data) => {
          setdata(data);
          // console.log(data);
          isl(false);
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    } catch (error) {
      console.log(error);
      alert("NOt Authorized ");
      setislogin(false);
      //   navigate("/");
    }
  };
  const handleSubmit=async(e)=>{
    e.preventDefault();
    // console.log(url,desc,title);
  
    // console.log("called");

   
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const videoId = await extractYouTubeId(url); 
      const { data } = await axios.post(
        "https://i-science-ser.vercel.app/api/v1/forms/Video",
        {
          title: title,
          description: desc,
          videoid: videoId, 
        },
        config
      );

       alert("Video Added Successfully");

        settitle("");
        seturl("");
        setdesc("");

  }
  catch(e){
      console.log(e);
      alert("Some error occured please try again later");

  }
}
  useEffect(() => {
    let user = localStorage.getItem("token");
    if (user) {
      setislogin(true);
      isl(true);
      getdata();
    }
  }, [act, islogin]);

  return (
    <>
      {islogin ? (
        <>
          <div>
            <div className="button_tip">
              <button
                className="custom-button"
                onClick={(e) => {
                  setact("question");
                }}
              >
                Questions
              </button>
              <button
                className="custom-button"
                onClick={(e) => {
                  setact("enquiry");
                }}
              >
                Contact us
              </button>
              <button
                className="custom-button"
                onClick={() => setact("contact")}
              >
                Enquiry
              </button>
              <button
                className="custom-button"
                onClick={() => setact("form")}
              >
                Add video
              </button>
              <button
                className="custom-button"
                onClick={() => {
                  localStorage.removeItem("token");
                  setislogin(false);
                  navigate("/");
                }}
              >
                Logout
              </button>
            </div>
            {loading ? (
              <div className="loadd" style={{ textAlign: "center" }}>
                <h1>Loading...</h1>
              </div>
            ) : (
              <>
             {act!=="form" && <List fdata={data} act={act} />}
             </>
            )}
            {act==="form" && 
            <>
            <div className="center_kar">
            <div className="form-container">
            <div className="form-content">
              <form
                className="send-message-form"
                onSubmit={handleSubmit}
              >
                <label htmlFor="title">Title*</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Enter title"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                  required={true}
                />
                <label htmlFor="Desc">Description *</label>
                <input
                  type="Text"
                  id="desc"
                  name="Desc"
                  placeholder="Enter Description"
                  value={desc}
                  onChange={(e) => setdesc(e.target.value)}
                  required={true}
                />
                <label htmlFor="Desc">Link *</label>
                <input
                  type="url"
                  id="link"
                  name="Link"
                  placeholder="Enter Url of the video"
                  value={url}
                  onChange={(e) => seturl(e.target.value)}
                  required={true}
                />
                <div className="btn_submit_full">
                  <button type="submit">Send</button>
                </div>
              </form>
              </div>
             </div>
              </div>
            </>}
          </div>
        </>
      ) : (
        <h1>You are not authorized </h1>
        )}
        <ToastContainer/>
    </>
  );
};

export default Admin;
