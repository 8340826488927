// import React from 'react';
// import pdf from "./results.pdf"

// const ViewerComponent = () => {
//   const zoomLevel = 100; // Adjust the zoom level as needed

//   const pdfUrlWithoutToolbar = `${pdf}#view=FitH&zoom=${zoomLevel}&toolbar=0`;

//   return (

// <div style={{ maxWidth: '100%', height: '100vh', overflow: 'hidden' }}>
// <iframe
//   title="PDF Viewer"
//   src={pdfUrlWithoutToolbar}
//   width="100%"
//   height="100%"
//   style={{ border: 'none', maxWidth: '100%' }}
//   allowFullScreen
//   controls
// />
// </div>

//   );
// };

// export default ViewerComponent;
import React from "react";
import pdf from "./results.pdf";
import imgg from "./0_0_5.jpg";

const ViewerComponent = ({ popup }) => {
  const zoomLevel = 100;

  const pdfUrlWithoutToolbar = `${pdf}#view=FitH&zoom=${zoomLevel}&toolbar=0`;

  return (
    <div
      style={{ width: "100%", height: "100vh", overflow: "auto" }}
      className={popup ? "blurify" : ""}
    >
      <object
        data={pdfUrlWithoutToolbar}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        {/* <div className="tempx"></div> */}
        {/* <p>
          It appears you don't have a PDF plugin for this browser.
          No worries, you can <a href={pdf}>download the PDF file.</a>
        </p> */}
        <img
          src={imgg}
          style={{ overflow: "auto", width: "100%" }}
          alt="results"
        />
      </object>
    </div>
  );
};

export default ViewerComponent;
