import React, { useState, useEffect } from "react";
import "./team.css";
import { motion } from "framer-motion";

const AboutSection = ({ title, description, image, reverse, idd }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const getImage = async () => {
      try {
        const importedImage = await import(`../../Assets/${image}`);
        setImageUrl(importedImage.default);
      } catch (error) {
        console.error(`Error importing image: ${image}`, error);
      }
    };

    getImage();
  }, [image]);

  useEffect(() => {
    window.scrollTo({
      top: 10,
      behavior: "smooth",
    });
  }, []);
  
  return (
    <>
      {reverse ? (
        <motion.div
          initial={{ x: "-100%", opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          exit={{
            x: "-100%",
            opacity: 0,
            transition: { duration: 0, delay: 0 },
          }}
          transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
        >
          <div className={`about-section ${reverse ? "reverse" : ""}`}>
            <div className="about-section-image">
              {imageUrl && <img src={imageUrl} alt={title} id={idd} />}
            </div>
            <div className="about-section-content">
              <h2 className="about-section-title">{title}</h2>
              <p className="about-section-description">{description}</p>
            </div>
          </div>
        </motion.div>
      ) : (
        <>
          <motion.div
            className="videoText"
            initial={{ x: "100%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            exit={{
              x: "100%",
              opacity: 0,
              transition: { duration: 0, delay: 0 },
            }}
            transition={{ duration: 0.5, delay: 0.6, ease: "easeOut" }}
          >
            <div className={`about-section ${reverse ? "reverse" : ""}`}>
              <div className="about-section-image">
                {imageUrl && <img src={imageUrl} alt={title} id={idd} />}
              </div>
              <div className="about-section-content">
                <h2 className="about-section-title">{title}</h2>
                <p className="about-section-description">{description}</p>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </>
  );
};

const OurAbout = ({popup}) => {
  return (
    <div className={popup?"blurify our-abouts":""}>
      <h1 className="page-title">Our Founder</h1>

      <AboutSection
        title="Vijay Jadhav"
        description="Meet the driving force behind iScience Pvt Tutions, Mr. Vijay Jadhav. A seasoned educator with a profound passion for nurturing young minds, Vijay embarked on a mission to revolutionize the educational landscape. Armed with a wealth of experience in teaching and an unwavering commitment to student success, he founded iScience Pvt Tutions to provide a cutting-edge and student-centric learning experience. Vijay's vision is to empower students with the knowledge and skills necessary to thrive in a rapidly evolving world, and his leadership has steered iScience Pvt Tutions to become a beacon of educational excellence."

        image="vijay_sir.png"
        idd="vijay_sir"
      />

      <h1 className="page-title">Our Co-Founder</h1>
      <AboutSection
        title="Ronak Jadhav"
        description="Introducing Mr. Ronak Jadhav, the co-founder of iScience Pvt Tutions and a driving force behind the institution's technological evolution. Ronak's journey in education technology began with a deep-rooted belief in the transformative power of digital learning. Leveraging his background in technology and a keen understanding of pedagogical methodologies, Ronak has played a pivotal role in shaping iScience Pvt Tutions into a modern and innovative educational hub. His commitment to harnessing technology for effective teaching, coupled with a focus on creating engaging and interactive learning experiences, sets iScience Pvt Tutions apart as a pioneer in the realm of online education."

        image="ronak_sir.png"
        reverse={true}
        idd="ronak_sir"
      />
    </div>
  );
};

export default OurAbout;
